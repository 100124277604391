/**
 * Nosy Quote Mark
 *
 * @export QuoteMark
 */
import { chakra } from '@chakra-ui/react'
import React from 'react'
  
const QuoteMark = ( props ) => (
    <chakra.svg 
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 72.72 48.62'
        color='gray.50'
        height={8}
        {...props}>
        <path 
            fill='currentColor' 
            d='M22,48.62H0L16.69,0h19Zm37.08,0h-22L53.76,0h19Z' />
    </chakra.svg>
)
  
export default QuoteMark
  