/**
 * Nosy Expo - Feedback Form
 */
import {
    Avatar,
    Box,
    Button,
    Center,
    Container,
    Flex,
    FormControl,
    FormErrorMessage, FormHelperText, FormLabel,
    Input,
    Stack, Textarea, useToast
} from '@chakra-ui/react'
import Filter from 'bad-words'
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import {
    addDoc,
    collection,
    getFirestore,
    serverTimestamp
} from 'firebase/firestore'
import { Fragment, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import Helmet from 'react-helmet'
import { useForm } from 'react-hook-form'
import { FaGoogle } from 'react-icons/fa'
import firebaseApp from '../../utils/firebase'
import Header from '../header/header'

// Constants
const MAX_CHARACTERS = 160

// Instantiate profanity filter
const filter = new Filter()

const auth = getAuth(firebaseApp)
const firestore = getFirestore(firebaseApp)

const FeedbackForm = () => {
    // Handle auth
    const [user, loading] = useAuthState(auth)
    const provider = new GoogleAuthProvider()

    // Handle auth
    const handleLogin = () => {
        signInWithPopup(auth, provider)
    }

    // Instantiate React Hook Form
    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting },
        reset
    } = useForm({ mode: 'onBlur' })

    // Instantiate Toast
    const toast = useToast()

    // Handle submit
    const onSubmit = async (values) => {
        // Destructure form values
        const { feedback = '', name = '', company = '' } = values

        // Check input values for profanity/innappropriate language
        if ( filter.isProfane([feedback, name, company]) ) {

            toast({
                position: 'bottom',
                status: 'warning',
                title: 'No profanities thank you'
            })

        } else {
            const docRef = await addDoc(
                collection(firestore, 'feedbackCollection'),
                {
                    name: name,
                    feedback: feedback,
                    company: company,
                    timestamp: serverTimestamp()
                }
            )
    
            // Show success toast & reset form if we receive an doc.id
            if (docRef.id !== null) {

                toast({
                    position: 'bottom',
                    status: 'success',
                    title: 'Thank you'
                })

                reset()
            }
        }
    }

    // Handle numbert of characters
    const [charCount, setCharCount] = useState(0)

    return (
        <Fragment>
            <Helmet title='NOSY : Submit Feedback' />
            <Stack
                bg='brand_grayscale.black'
                direction='column'
                minHeight='100vh'
                spacing={12}>
                <Header />
                <Flex flex='1' flexDirection='column'>
                    <Container
                        maxW='container.xl'
                        flex='1'
                        justifyContent='center'
                        centerContent>
                        {user ? (
                            <Box as='form' onSubmit={handleSubmit(onSubmit)}>
                                <Stack direction='column' spacing={4}>
                                    <FormControl isInvalid={errors.feedback}>
                                        <Flex 
                                            justifyContent='space-between'
                                            alignItems='center'>
                                        <FormLabel
                                            htmlFor='feedback'
                                            color='gray.50'>
                                            Feedback
                                        </FormLabel>
                                        <FormHelperText 
                                            color={ charCount > MAX_CHARACTERS ? 'red.500' : 'gray.500' }
                                            mb={2} 
                                            mt={0}>
                                            {charCount}/{MAX_CHARACTERS}
                                        </FormHelperText>
                                        </Flex>
                                        <Textarea
                                            {...register('feedback', {
                                                required:
                                                    'Please supply Feedback',
                                                maxLength: {
                                                    value: MAX_CHARACTERS,
                                                    message:
                                                        `Please be more concise - max ${MAX_CHARACTERS} characters`
                                                }
                                            })}
                                            id='feedback'
                                            rows='5'
                                            width='sm'
                                            placeholder='What action could your business take to help our planet?'
                                            color='gray.50'
                                            borderColor='brand_primary.700'
                                            focusBorderColor='brand_secondary.500'
                                            onChange={(e) => setCharCount( e.target.value.length )}
                                        />
                                        <FormErrorMessage>
                                            {errors.feedback &&
                                                errors.feedback.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={errors.name}>
                                        <FormLabel
                                            htmlFor='name'
                                            color='gray.50'>
                                            Name
                                        </FormLabel>
                                        <Input
                                            {...register('name', {
                                                required: 'Please supply your Name',
                                                maxLength: {
                                                        value: 60,
                                                        message: 'max 60 characters'
                                                }
                                            })}
                                            id='name'
                                            placeholder='Name'
                                            type='text'
                                            color='gray.50'
                                            borderColor='brand_primary.700'
                                            focusBorderColor='brand_secondary.500'
                                        />
                                        <FormErrorMessage>
                                            {errors.name && errors.name.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={errors.company}>
                                        <FormLabel
                                            htmlFor='company'
                                            color='gray.50'>
                                            Company
                                        </FormLabel>
                                        <Input
                                            {...register('company', {
                                                required: 'Please supply your company',
                                                maxLength: {
                                                    value: 60,
                                                    message: 'max 60 characters'
                                            }
                                            })}
                                            id='company'
                                            placeholder='Company'
                                            type='text'
                                            color='gray.50'
                                            borderColor='brand_primary.700'
                                            focusBorderColor='brand_secondary.500'
                                        />
                                        <FormErrorMessage>
                                            {errors.company &&
                                                errors.company.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                    <Center>
                                        <Button
                                            isLoading={isSubmitting}
                                            size='lg'
                                            bg='brand_primary.700'
                                            color='gray.50'
                                            mt={4}
                                            _hover={{
                                                bg: 'brand_primary.800'
                                            }}
                                            type='submit'>
                                            Send Feedback
                                        </Button>
                                    </Center>
                                </Stack>
                            </Box>
                        ) : (
                            <Container
                                maxW='container.xl'
                                flex='1'
                                justifyContent='center'
                                centerContent>
                                <Stack direction='column' spacing={6}>
                                    <Center>
                                        <Avatar
                                            size='xl'
                                            bg='brand_primary.700'
                                        />
                                    </Center>
                                    <Button
                                        onClick={handleLogin}
                                        isLoading={loading}
                                        bg='brand_primary.700'
                                        color='gray.900'
                                        leftIcon={<FaGoogle />}
                                        _hover={{
                                            bg: 'brand_primary.800'
                                        }}>
                                        Login
                                    </Button>
                                </Stack>
                            </Container>
                        )}
                    </Container>
                </Flex>
            </Stack>
        </Fragment>
    )
}

export default FeedbackForm
