/**
 * Nosy Tools
 *
 * Header
 *
 */
import {
    Container,
    Flex
} from '@chakra-ui/react'
// import firebaseApp from '../../utils/firebase'
import { Mark } from '../brand'

const Header = () => {
    return (
        <Flex 
            as='header'
            position='sticky' 
            zIndex='docked'>
            <Container maxW='container.xl'>
                <Flex
                    alignItems='center'
                    justifyContent='center'
                    paddingY={[3, 6]}>
                    <Mark color='brand_primary.700' width={10} height={10} />
                </Flex>
            </Container>
        </Flex>
    )
}

export default Header
