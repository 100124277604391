/**
 * Nosy Circle
 *
 * @export Circle
 */
 import { chakra } from '@chakra-ui/react'
import React from 'react'
   
 const Circle = ( props ) => (
    <chakra.svg 
         xmlns='http://www.w3.org/2000/svg'
         viewBox="0 0 1080 1080"
         color='gray.50'
         height={8}
         {...props}>
        <circle
            fill='currentColor' 
            cx="540" 
            cy="540" 
            r="540"/>
    </chakra.svg>
)
   
export default Circle