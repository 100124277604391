/**
 * Nosy Expo
 *
 * Firebase configuration
 */
import { initializeApp } from 'firebase/app'

const firebaseConfig = {
    apiKey: 'AIzaSyB1dZmn4_F8mC5pQmiSHm3553FsR6HTimE',
    authDomain: 'nosy-expo.firebaseapp.com',
    databaseURL:
        'https://nosy-expo-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'nosy-expo',
    storageBucket: 'nosy-expo.appspot.com',
    messagingSenderId: '958208363891',
    appId: '1:958208363891:web:c22545a6bb5923c10e8906'
}

const firebaseApp = initializeApp(firebaseConfig)

export default firebaseApp
