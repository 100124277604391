/**
 * Nosy Chakra UI Theme FontLoader
 * 
 * In this component we provide preload links within the site <head> and
 * load font files via css @font-face directives.
 *
 * @export FontLoader
 */

 import { Global } from '@emotion/react'
import React from 'react'
  
 const FontLoader = () => {
     return (
         <React.Fragment>
             <Global
                 styles={`
                  /* Modelica ------------------------------- */
                  
                  /* Regular */
                  @font-face {
                      font-family: 'Modelica-Regular';
                      font-style: normal;
                      font-weight: 400;
                      font-display: swap;
                      unicode-range: U+000-5FF; /* Download only latin glyphs */
                    src:local('Modelica-Regular'), url('fonts/bw-modelica/woff2/BwModelica-Regular.woff2') format('woff2');
                  }
  
                  /* Medium */
                  @font-face {
                      font-family: 'Modelica-Medium';
                      font-style: normal;
                      font-weight: 500;
                      font-display: swap;
                      unicode-range: U+000-5FF; /* Download only latin glyphs */
                      src:local('Modelica-Medium'), url('fonts/bw-modelica/woff2/BwModelica-Medium.woff2') format('woff2');
                  }
                  
                  /* Bold */
                  @font-face {
                      font-family: 'Modelica-Bold';
                      font-style: normal;
                      font-weight: 700;
                      font-display: swap;
                      unicode-range: U+000-5FF; /* Download only latin glyphs */
                      src:local('Modelica-Bold'), url('fonts/bw-modelica/woff2/BwModelica-Bold.woff2') format('woff2');
                  }
  
                  /* End Modelica ------------------------------- */
  
  
  
                  /* Modelica Stylized Set (01) ----------------- */
  
                  /* Bold */
                  @font-face {
                      font-family: 'Modelica-SS01-Bold';
                      font-style: normal;
                      font-weight: 700;
                      font-display: swap;
                      unicode-range: U+000-5FF; /* Download only latin glyphs */
                      src:local('Modelica-SS01-Bold'),
                          url('fonts/bw-modelica/woff2/BwModelicaSS01-Bold.woff2') format('woff2');
                  }
  
                  /* End Modelica Stylized Set (01) ----------------- */
  
  
  
                  /* Modelica Stylized Set (02) ----------------- */
  
                  /* Bold */
                  @font-face {
                      font-family: 'Modelica-SS02-Bold';
                      font-style: normal;
                      font-weight: 700;
                      font-display: swap;
                      unicode-range: U+000-5FF; /* Download only latin glyphs */
                      src:local('Modelica-SS02-Bold'),
                          url('fonts/bw-modelica/woff2/BwModelicaSS02-Bold.woff2') format('woff2');
                  }
  
                  /* End Modelica Stylized Set (02) ------------- */
              `} />
         </React.Fragment>
     )
 }
 export default FontLoader
  