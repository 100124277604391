/**
 * Nosy Expo App
 */
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Display from './components/display/display'
import Export from './components/export/export'
import FeedbackForm from './components/feedback-form/feedback-form'

const App = () => (
    <BrowserRouter>
        <Routes>
            <Route exact path='/' element={<FeedbackForm />} />
            <Route exact path='/display' element={<Display />} />
            <Route exact path='/export' element={<Export />} />
        </Routes>
    </BrowserRouter>
)

export default App
