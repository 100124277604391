/**
 * Nosy Expo
 *
 * Create React App Entry point
 */
import { ChakraProvider } from '@chakra-ui/react'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Helmet } from 'react-helmet'
import App from './app'
import theme from './assets/theme/theme'
import FontLoader from './components/font-loader/font-loader'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)
root.render(
    <StrictMode>
        <Helmet title='Nosy Tools' />
        <ChakraProvider theme={theme}>
            <FontLoader />
            <App />
        </ChakraProvider>
    </StrictMode>
)
