/**
 * Nosy Expo - Display
 */
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Container,
    Flex,
    Heading,
    Spinner,
    Stack,
    Text
} from '@chakra-ui/react'
import {
    collection,
    getFirestore,
    limit,
    orderBy,
    query
} from 'firebase/firestore'
import { AnimatePresence, motion } from 'framer-motion'
import { Fragment, useState } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import Helmet from 'react-helmet'
import { useInterval } from 'react-use'
import firebaseApp from '../../utils/firebase'
import { Mark } from '../brand'
import Circle from '../circle/circle'
import QuoteMark from '../quote-mark/quote-mark'

// Constants
const SLIDE_DURATION = 7000
const SLIDE_LIMIT = 50

// Get db reference
const firestore = getFirestore(firebaseApp)

/**
 * Create Motion-Favoured Flex components (outside of App component)
 * @link https://github.com/framer/motion/issues/1441#issuecomment-1029034715
 * Thanks to @mattgperry for the fix
 */
const MotionBox = motion(Box)

const Display = () => {
    // Get collection data
    const [values, loading, error] = useCollectionData(
        query(
            collection(firestore, 'feedbackCollection'),
            orderBy('timestamp', 'desc'),
            limit(SLIDE_LIMIT)
        ),
        {
            snapshotListenOptions: {
                includeMetadataChanges: true
            }
        }
    )

    // Manage slide index in local state
    const [currentIndex, setCurrentIndex] = useState(0)

    // Use interval to advance current slide index
    useInterval(() => {
        if (currentIndex < values.length - 1) {
            // console.log(`🦄 Advancing index from ${currentIndex} to ${(currentIndex + 1)}`)
            setCurrentIndex(currentIndex + 1)
        } else {
            // console.log('🦄 Resetting index')
            setCurrentIndex(0)
        }
    }, SLIDE_DURATION)

    return (
        <Fragment>
            <Helmet title='NOSY : Display Feedback' />
            <Container
                centerContent
                display='flex'
                flexDirection='column'
                bg='brand_grayscale.black'
                minWidth='100vw'
                minHeight='100vh'
                width='full'
                height='full'
                p={0}
                m={0}>
                <Flex
                    position='absolute'
                    justifyContent='center'
                    pt={8}
                    top={0}
                    width='full'>
                    <Mark color='brand_primary.700' width={10} height={10} />    
                </Flex>
                {error && (
                    <Fragment>
                        <Alert status='error' maxWidth={'xl'}>
                            <AlertIcon />
                            <AlertTitle mr={2}>Uh oh!</AlertTitle>
                            <AlertDescription>
                                Error fetching data.
                            </AlertDescription>
                        </Alert>
                    </Fragment>
                )}
                {loading && (
                    <Flex
                        width='100vw'
                        height='100vh'
                        justifyContent='center'
                        alignItems='center'>
                        <Spinner
                            my={20}
                            color='brand_secondary.500'
                            size='xl'
                        />
                    </Flex>
                )}
                {values && (
                    <MotionBox position='relative' width='100vw' height='100vh'>
                        <AnimatePresence>
                            {values.map(
                                (
                                    { feedback = '', name = '', company = '' },
                                    index
                                ) => (
                                    <MotionBox
                                        key={index}
                                        custom={index}
                                        position='absolute'
                                        top={0}
                                        right={0}
                                        bottom={0}
                                        left={0}
                                        display='flex'
                                        flexDirection='column'
                                        justifyContent='center'
                                        alignItems='center'
                                        width='100vw'
                                        height='100vh'
                                        variants={{
                                            hidden: {
                                                opacity: 0
                                            },
                                            visible: {
                                                opacity: 1
                                            }
                                        }}
                                        initial='hidden'
                                        animate={
                                            index === currentIndex
                                                ? 'visible'
                                                : 'hidden'
                                        }
                                        exit='hidden'>
                                        <Stack
                                            direction='row'
                                            spacing={6}
                                            ml={-12}>
                                            <QuoteMark color='brand_secondary.500' />
                                            <Box>
                                                <Heading
                                                    fontFamily='Modelica-Bold'
                                                    fontSize='7xl'
                                                    maxWidth='4xl'
                                                    color='gray.50'
                                                    mb={2}>
                                                    {feedback}
                                                </Heading>
                                                <Text
                                                    color='brand_secondary.500'
                                                    fontSize='3xl'>
                                                    {name} - {company}
                                                </Text>
                                            </Box>
                                        </Stack>
                                    </MotionBox>
                                )
                            )}
                        </AnimatePresence>
                        <Flex
                            position='absolute'
                            justifyContent='center'
                            pb={8}
                            bottom={0}
                            width='full'>
                            {values &&
                                values.map((_, index) => (
                                    <Circle
                                        key={index}
                                        mx={1}
                                        height={2}
                                        color={
                                            index === currentIndex
                                                ? 'brand_secondary.500'
                                                : 'brand_grayscale.dark'
                                        }
                                    />
                                ))}
                        </Flex>
                    </MotionBox>
                )}
            </Container>
        </Fragment>
    )
}

export default Display
