/**
 * Nosy Expo - Export entries
 */

import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Container,
    Flex,
    Heading,
    Spinner,
    Stack
} from '@chakra-ui/react'
import { collection, getFirestore, orderBy, query } from 'firebase/firestore'
import React, { Fragment } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import firebaseApp from '../../utils/firebase'

// Get db reference
const firestore = getFirestore(firebaseApp)

const Export = () => {
    // Get collection data
    const [values, loading, error] = useCollectionData(
        query(
            collection(firestore, 'feedbackCollection'),
            orderBy('timestamp', 'desc')
        ),
        {
            snapshotListenOptions: {
                includeMetadataChanges: true
            }
        }
    )

    return (
        <Container
            centerContent
            display='flex'
            flexDirection='column'
            bg='brand_grayscale.black'
            minWidth='100vw'
            width='full'
            height='full'
            pb={24}
            mb={0}>
            {error && (
                <Fragment>
                    <Alert status='error' maxWidth={'xl'}>
                        <AlertIcon />
                        <AlertTitle mr={2}>Uh oh!</AlertTitle>
                        <AlertDescription>
                            Error fetching data.
                        </AlertDescription>
                    </Alert>
                </Fragment>
            )}
            {loading && (
                <Flex
                    width='100vw'
                    height='100vh'
                    justifyContent='center'
                    alignItems='center'>
                    <Spinner my={20} color='brand_secondary.500' size='xl' />
                </Flex>
            )}
            {values && (
                <Fragment>
                    <Flex 
                        py={12} 
                        spacing={6}
                        direction='column'>
                        <Heading 
                            as='h1'
                            fontSize='7xl' 
                            color='brand_primary.700'>Expo Submissions</Heading>
                    </Flex>
            
                    {values.map(({ feedback = '', name = '', company = '' }) => (
                        <Stack
                            direction='column'
                            maxWidth='4xl'
                            width='full'
                            py={12}>
                            <Heading as='h1' fontSize='4xl' color='brand_grayscale.white' mb={6}>{feedback}</Heading>
                            <Heading as='h2' fontSize='2xl' color='brand_secondary.500'>
                                {name} - {company}
                            </Heading>
                        </Stack>
                    ))}
                </Fragment>
            )}
        </Container>
    )
}

export default Export
